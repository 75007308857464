function activateVideo(object, single = false) {
  if (single) {
    var videourl = object.attr('href') + '&autoplay=1';
    object.parent().parent().siblings('iframe').attr('src', videourl);
  } else {
    object.each(function() {
      var videourl = $(this).attr('href');
      $(this).parent().parent().siblings('iframe').attr('src', videourl);
    });
  }
  object.addClass('active');
	object.parent().parent().siblings('picture').hide();
  object.parent().parent().siblings('img').hide();
  object.parent().parent().hide();
}

function checkCookie() {
  if (hasCookie('youtube-active')) {
    activateVideo($('a.youtube-start'));
  }
}

$('.youtube-active').click(function() {
  if ($(this).is(':checked')) {
		activateVideo($('a.youtube-start'));
    setCookie('youtube-active', 'true', 30);
  }
});


$('a.youtube-start').click(function(e) {
  e.preventDefault();
  activateVideo($(this), true);
});

$(document).ready(function() {
  checkCookie();
});
